import instance from "./base"
function Login(email, password){
    return instance.post('/user/login', {
        email: email,
        password: password
    })
}

function Register(email, password){
    return instance.post('/user/register', {
        email: email,
        password: password
    })
}

export {Login, Register}