import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { GetUsers } from '../../../api/admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { CustomNoRowsOverlay, ProgressBar } from '../../../componenet/componenet';
import { TransferKiloBytesToGB, TransferKiloBytesToMB } from '../../../util/util';


const formatSinceDate = (params) => {
    if (params.value === 0) {
        return 'Never'
    }
    const date = new Date(params.value * 1000)
    const now = new Date()
    const diff = date.getTime() - now.getTime()
    const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12))
    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours = Math.floor(diff / (1000 * 60 * 60))
    const minutes = Math.floor(diff / (1000 * 60))

    let since = ''
    if (years > 0) {
        since += `${years} years `
    } if (months > 0) {
        since += `${months % 12} months `
    }
    if (days > 0) {
        since += `${days % 30} days `
    }
    if (hours > 0) {
        since += `${hours % 24} hours `
    }
    if (minutes > 0) {
        since += `${minutes % 60} minutes`
    }
    if (since !== '') {
        return since
    }
    return 'Expired'
}

function getColumns(onEdit) {
    const formatBytes = (isMb) => {
        return (d)=>(isMb?TransferKiloBytesToMB(d.value) + 'MB':TransferKiloBytesToGB(d.value) + 'GB')
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'balance', headerName: 'Balance' },
        {
            field: 'currentDownloadTraffic', headerName: 'Download', type: 'number', valueFormatter:formatBytes(true)
        },
        {
            field: 'currentUploadTraffic',
            headerName: 'Upload',
            type: 'number',
            valueFormatter:formatBytes(true)
        },
        { field: 'transferAvailable', headerName: 'Available', type: 'number', valueFormatter: formatBytes(false), width: 150 },
        { field: 'transferTotal', headerName: 'Usage', type: 'number', valueFormatter: formatBytes(false) },
        { field: 'regIP', headerName: 'RegIP', width: 150 },
        {
            field: 'subscriptionExpireIn', headerName: 'Expire In', type: 'date', width: 200, valueGetter: (params) => {
                const date = new Date(params.value)
                return date
            },
            valueFormatter: formatSinceDate
        },
        // {
        //     field: 'nextRefreshTime', headerName: 'Next Refresh', type: 'date', width: 200, valueGetter: (params) => {
        //         const date = new Date(params.value)
        //         return date
        //     },
        //     valueFormatter: formatSinceDate,
        // },
        { field: 'isAdmin', headerName: 'Is Admin', type: 'boolean' },
        {
            field: 'Progress', 
            headerName: 'Progress', 
            width: 150,  
            type: 'number',
            renderCell: (params) => { 
                console.log(params)
                const row = params.row
                const progress = (row.transferTotal / row.transferAvailable).toFixed(2)
                return (
                    <ProgressBar  value={progress}/>
                )
            },
            valueGetter: (params) => {
                const row = params.row
                const progress = (row.transferTotal / row.transferAvailable).toFixed(2)
                return progress
            }
        },
        {
            field: 'Edit', headerName: '', width: 100, renderCell: (params) => {
                return (
                    <button className='text-blue-700 font-bold' onClick={() => onEdit(params.id)}>Edit</button>
                )
            }
        }
    ]

    return columns
}
function DashboaradUser() {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(true)
    const [rows, setRows] = React.useState([])
    const makeRepeated = (arr, repeats) =>
        [].concat(...Array.from({ length: repeats }, () => arr));

    const fetchAllUsers = async () => {
        let page = 1
        let list = []
        while (true) {
            const resp = await GetUsers(page, 100)
            if (resp.data.data.list.length === 0) {
                break
            }
            list = list.concat(resp.data.data.list)
            page++
        }
        return makeRepeated(list, 1)
    }
    const onEdit = (params) => {
        // console.log(params)
        navigate(`/dashboard/user/${params}`)
    }
    const refresh = async () => {
        setLoading(true)
        const list = await fetchAllUsers()
        setRows(list)
        setLoading(false)
    }
    React.useEffect(() => {
        async function fetchData() {
            try {
                const list = await fetchAllUsers()
                setRows(list)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])
    const columns = getColumns(onEdit)
    return (
        <React.Fragment>
            <Button variant="contained" className='bg-white w-fit' onClick={refresh}>Refresh</Button>
            <DataGrid
                className='mt-4'
                rows={rows}
                columns={columns}
                loading={loading}
                style={{height: '80vh'}}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: CustomNoRowsOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10, 50]}
            // checkboxSelection
            />
        </React.Fragment>
    );
}

export { DashboaradUser }