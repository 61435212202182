import {  RouterProvider, createBrowserRouter } from 'react-router-dom';
import {LoginPage} from './pages/login/login';
import * as React from 'react';
import { Home } from './pages/home/home';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Dashboard } from './pages/dashboard/dashboard';
import DashboardHome from './pages/dashboard/home/home';
import { DashboaradUser } from './pages/dashboard/user/user';
import { DashboardUserDetail } from './pages/dashboard/user/detail';
function App() {
  const router =  createBrowserRouter(
    [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          {
            index: true,
            element: <DashboardHome />,
            path: '/dashboard/*'
          },
          {
            element: <div>Dashboard Order</div>,
            path: '/dashboard/order'
          },
          {
            element: <DashboardUserDetail />,
            path: '/dashboard/user/:id'
          },
          {
            element: <DashboaradUser/>,
            path: '/dashboard/user'
          },
          {
            element: <div>Dashboard Node</div>,
            path: '/dashboard/node'
          },
          {
            element: <div>Dashboard Traffic</div>,
            path: '/dashboard/traffic'
          }
        ]
      }
    ]
  );
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}



export default App;
