import React, { useState } from 'react'
import { AppBar, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, ListItemButton, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import DraftsIcon from '@mui/icons-material/Drafts';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GroupIcon from '@mui/icons-material/Group';
import StorageIcon from '@mui/icons-material/Storage';
import CellTowerIcon from '@mui/icons-material/CellTower';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LogoutIcon from '@mui/icons-material/Logout';
function Dashboard() {
    const navigate = useNavigate()
    const location = useLocation();
    const [open, setOpen] = useState(false)
    // const [page, setPage] = useState('home')
    const [isAdmin, setIsAdmin] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0);

    const userMenu = [
        {
            key: 'home',
            name: 'Home',
            icon: <HomeIcon />,
            page: <div>Home</div>
        },
        {
            key: 'order',
            name: 'Order',
            icon: <ShoppingCartIcon />,
            page: <div>Order</div>
        }
    ]
    const adminMenu = [
        {
            key: 'user',
            name: 'User',
            icon: <GroupIcon />,
            page: <div>User</div>
        },
        {
            key: 'node',
            name: 'Node',
            icon: <StorageIcon />,
            page: <div>Node</div>
        },
        {
            key: 'traffic',
            name: 'Traffic',
            icon: <CellTowerIcon />,
            page: <div>Traffic</div>
        }
    ]
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const onClickMenu = (e, index) => {
        setSelectedIndex(index)
        navigate("/dashboard/" + (index > userMenu.length - 1 ? adminMenu[index - userMenu.length].key : userMenu[index].key))
        toggleDrawer()
    }

    const DashboardAppBar = () => {
        return <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
                    <MenuIcon />
                </IconButton>
                {selectedIndex > userMenu.length - 1 ? adminMenu[selectedIndex - userMenu.length].name : userMenu[selectedIndex].name}
            </Toolbar>
        </AppBar>
    }
    const onLogout = () => {
        localStorage.removeItem('token')
        navigate('/login')
    }
    const DashboardDrawer = <Drawer
        open={open}
        onClose={toggleDrawer}
        anchor='left'
    >
        <Box
            role="presentation"
            sx={{
                display:'flex',
                flexDirection:'column',
                height:'100vh',
            }}
        >
            <List>
                {userMenu.map((item, index) => (
                    <ListItemButton key={index} onClick={(e) => { onClickMenu(e, index) }} selected={index === selectedIndex}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                ))}
            </List>
            {isAdmin && <Divider />}
            <List>
                {
                    isAdmin && adminMenu.map((item, index) => (
                        <ListItemButton key={index} onClick={(e) => { onClickMenu(e, index + userMenu.length) }} selected={(index + userMenu.length) === selectedIndex}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    ))
                }

            </List>
            <div className='mt-auto'>
                <List>
                    <ListItemButton onClick={onLogout}>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItemButton>
                </List>
            </div>
        </Box>
    </Drawer>
    return (
        <React.Fragment>
            <DashboardAppBar />
            {DashboardDrawer}
            {/* {selectedIndex > userMenu.length - 1 ? adminMenu[selectedIndex - userMenu.length].page : userMenu[selectedIndex].page} */}
            {
                location.pathname.match("/dashboard/user/[0-9]*") ?
                    <Outlet /> :
                    <div variant="outlined" className='m-2 shadow-2xl p-4 rounded-md h-fit'><Outlet /></div>
            }
        </React.Fragment>
    )
}

export { Dashboard }