import { useParams, useNavigate } from "react-router-dom";
import * as React from 'react';
import { Button, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { GetUserInfo } from "../../../api/user";
import { TransferKiloBytesToGB, TransferKiloBytesToMB } from "../../../util/util";
import { GetUserTraffic, SetUserTraffic } from "../../../api/admin";
import { AreaChart, Tooltip, CartesianGrid, XAxis, YAxis, Area, Legend, ResponsiveContainer } from 'recharts';
import { Tabs, Tab } from '@mui/material';

function DashboardUserDetail() {
    const keepRecords = [
        'id',
        'email',
        'lastUsageTime',
        'currentUploadTraffic',
        'currentDownloadTraffic',
        'transferTotal',
        'transferAvailable',
        'balance',
        'isAdmin',

    ]
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState({});
    const [chartConfig, setChartConfig] = React.useState({
        data: [],
        duration: '15m',
        type: 'all',
        loading: true,
        index: 0,
        autoRefresh: true,
    });
    const [onEditTransfer, setOnEditTransfer] = React.useState({
        onEdit: false,
        available: 0,
        total: 0,
    });
    React.useEffect(() => {
        async function getUser() {
            const resp = await GetUserInfo(id);
            setUser(resp.data.data);
            await refreshChart();
            setLoading(false);
        }
        setLoading(true);
        getUser();
    }, [id])
    const navigate = useNavigate();
    function onBack() {
        navigate('/dashboard/user');
    }
    const refresh = async () => {
        setLoading(true);
        const resp = await GetUserInfo(id);
        setUser(resp.data.data);
        setOnEditTransfer({
            onEdit: false,
            available: 0,
            total: 0,
        });
        await refreshChart();
        setLoading(false);
    }
    const onEditSubmit = async () => {
        setLoading(true);
        const resp = await SetUserTraffic(user.id, Math.floor(onEditTransfer.available * 1024 * 1024), Math.floor(onEditTransfer.total * 1024 * 1024));
        // setUser(resp.data.data);
        // setLoading(false);
        refresh();
    }
    const onEditClick = () => {
        setOnEditTransfer({
            onEdit: true,
            available: TransferKiloBytesToGB(user.transferAvailable),
            total: TransferKiloBytesToGB(user.transferTotal),
        });
    }
    const onEditTransferChange = (e) => {
        console.log(parseFloat(e.target.value).toFixed(2));
        console.log(e.target);
        setOnEditTransfer({
            ...onEditTransfer,
            [e.target.name]: e.target.value
        });
    }
    const mapUserTraffic = (dateOption) => {
        return (traffic) => {
            let d = new Date(traffic.timestamp * 1000);
            const formattedDate = d.toLocaleString('UTC', dateOption);
            return {
                // ...traffic,
                'timestamp': formattedDate,
                'download': TransferKiloBytesToMB(traffic.download),
                'upload': TransferKiloBytesToMB(traffic.upload),
            }
        }
    }
    const refreshChart = async (index, unChangeIndex) => {
        if (unChangeIndex === true){

        }
        if (index === undefined) index = chartConfig.index;
        setChartConfig({
            ...chartConfig,
            loading: true,
            index: index,
        });
        const conf = {
            ...chartConfig,
        }
        conf.index = index;
        let dateOption = {}
        if (index === 0) {
            dateOption = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }
            conf.duration = '15m';
        } else if (index === 1) {
            dateOption = {
                hour: 'numeric',
                minute: '2-digit',
                // second: 'numeric',
            }
            conf.duration = '1h';
        } else if (index === 2) {
            dateOption = {
                hour: 'numeric',
                minute: 'numeric',
            }
            conf.duration = '1d';
        }
        const traffic = await GetUserTraffic(parseInt(id), conf.duration, conf.type);
        let data = traffic.data.data.map(mapUserTraffic(dateOption));
        conf.data = data;
        conf.loading = false;
        setChartConfig(conf);
    }
    const onTabChange = (e, i) => {
        if (i === chartConfig.index) return;
        setChartConfig({
            ...chartConfig,
            // index: i,
            loading: true,
        });
        refreshChart(i);
    }
    const onAutoRefreshChange = (e) => {
        setChartConfig({
            ...chartConfig,
            autoRefresh: e.target.checked,
        });
    }
    // if (chartConfig.autoRefresh) {
    //     setTimeout(() => {
    //         refreshChart();
    //     }, 30 * 1000);
    // }
    const items = Object.entries(user);
    const maxDomain = (Math.max.apply(Math, chartConfig.data.map(function(o) { return Math.max(o.download,o.upload); })))
    return <div className="flex flex-col w-full p-4">
        <div className="flex gap-2">
            <Button variant="contained" className='bg-white w-fit' onClick={onBack}>Back</Button>
            <Button variant="contained" className='bg-white w-fit' onClick={refresh}>Refresh</Button>
        </div>
        {
            loading ? <Box sx={{ display: 'flex' }}>
                <CircularProgress className="absolute top-0 bottom-0 m-auto left-0 right-0 text-2xl" />
            </Box> : (
                <div className="flex gap-4 mt-6  justify-between flex-auto w-full h-full">
                    <Box
                        className='flex flex-col sm:py-8 border-2 border-blue-400 rounded-xl shadow-lg max-w-sm p-4 gap-4'
                    >
                        <div className="font-bold text-4xl">
                            User Detail
                        </div>
                        <form className="flex flex-col gap-4 w-full">
                            {
                                items.map(([key, value]) => {
                                    if (keepRecords.includes(key)) {
                                        if (onEditTransfer.onEdit && ['transferAvailable', 'transferTotal'].includes(key)) {
                                            return <div key={key} variant="span">{key}: <TextField
                                                onChange={onEditTransferChange}
                                                type="number"
                                                // className='w-24'
                                                name={key === 'transferAvailable' ? 'available' : 'total'}
                                                variant="standard"
                                                InputProps={{
                                                    // startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                    endAdornment: <InputAdornment position="end">GB</InputAdornment>,
                                                }}
                                                size='small'
                                                value={key === 'transferAvailable' ? parseFloat(onEditTransfer.available) : parseFloat(onEditTransfer.total)}
                                            // value={parseFloat(TransferBytesToGB(onEditTransferAvailable.value))} 
                                            />

                                            </div>
                                        } else {
                                            let tail = ""
                                            if (['currentUploadTraffic', 'currentDownloadTraffic',].includes(key)) {
                                                value = TransferKiloBytesToMB(value)
                                                tail = "KB"
                                            } else if (['transferTotal', 'transferAvailable',].includes(key)) {
                                                value = TransferKiloBytesToGB(value)
                                                tail = "GB"
                                            } else if (key === 'isAdmin') {
                                                value = value ? '✅' : '❌'
                                            }
                                            return <div key={key} variant="span">{key}: {value} {tail} {['transferAvailable', 'transferTotal'].includes(key) ? <button className='text-blue-700 font-bold' onClick={onEditClick}>Edit</button> : <div></div>}</div>
                                        }
                                    }
                                    return null
                                })
                            }
                            {
                                onEditTransfer.onEdit ? <div className="flex gap-2">
                                    <Button variant="contained" className='bg-white w-fit' onClick={() => { setOnEditTransfer({ onEdit: false, available: 0, total: 0 }) }
                                    }>Cancel</Button>
                                    <Button variant="contained" className='bg-white w-fit' onClick={onEditSubmit}
                                    >Save</Button>
                                </div> : <div></div>

                            }
                        </form>
                    </Box>
                    <div className="w-full flex-1 flex-col gap-6  border-2 border-blue-400 rounded-xl shadow-lg p-4">
                        <Tabs value={chartConfig.index} onChange={onTabChange} aria-label="basic tabs example" className="mb-8">
                            <Tab label="15 Min" />
                            <Tab label="1 Hour" />
                            <Tab label="1 Day" />
                            <Button variant="outlined"  sx={{marginLeft:'auto'}} onClick={(e)=>{e.preventDefault();refreshChart()}}>Refresh</Button>
                        </Tabs>
                        {
                            false ? <Box sx={{ display: 'flex', width: "100%" }}>
                                <CircularProgress className="absolute top-0 bottom-0 m-auto left-0 right-0 text-2xl mx-auto" />
                            </Box> :
                                <ResponsiveContainer width="95%" height={500}>
                                    <AreaChart data={chartConfig.data}
                                        margin={{ top: 10, right: 30, left: 50, bottom: 0 }}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                            </linearGradient>
                                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="timestamp" />
                                        {/* <YAxis /> */}
                                        <YAxis label={{ value: 'network speed mb/s', angle: -90, position: 'left' }} domain={[0,maxDomain]} unit={'mb/s'}/>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Legend verticalAlign="top" height={36} />
                                        <Area type="monotone" dataKey="download" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                                        <Area type="monotone" dataKey="upload" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                        }
                    </div>
                </div>)
        }
    </div>
}

export { DashboardUserDetail };