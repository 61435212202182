import instance from "./base";

function GetUserInfo(id){
    if (id === undefined) {
        return instance.get(`/user/`)
    }
    return instance.get(`/user/${id}`)
}

function GetTraffic(duration){
    return instance.post(`/user/traffic`,{
        duration:duration
    })
}

export {GetUserInfo, GetTraffic}