import { Button, Container, TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { Login, Register } from '../../api/auth';
import { setToken } from '../../api/base';

function LoginPage() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const [inputForm, setInputForm] = React.useState({
        email: '',
        emailError: '',
        confirmPassword: '',
        confirmPasswordError: '',
        password: '',
        passwordError: '',
        isRegister: false,
        requestError: ''
    })

    const onLogin = async (e) => {
        e.preventDefault()
        let requestError=  ''
        if (inputForm.email === '') {
            requestError=  'Email is required'
        }else if (inputForm.password === '') {
            requestError ='Password is required'
        }else if (inputForm.password.length < 8) {
            requestError= 'Password must be at least 8 characters'
        }
        if (requestError) {
            setInputForm({
                ...inputForm,
                requestError: requestError
            })
            return
        }
        try {
            const resp = await Login(inputForm.email, inputForm.password)
            if (!resp.data.data) {
                setInputForm({
                    ...inputForm,
                    requestError: resp.data.message
                })
                return
            }
            localStorage.setItem('token', resp.data.data)
            setToken(resp.data.data)
            setInputForm({
                ...inputForm,
                requestError: ''
            })
            navigate('/dashboard')
        } catch (error) {
            setInputForm({
                ...inputForm,
                requestError: error.response.data.message
            })
        }
    }

    const onRegister = async (e) => {
        e.preventDefault();
        let requestError = '';
        if (inputForm.email === '') {
            requestError = 'Email is required';
        } else if (inputForm.password === '') {
            requestError = 'Password is required';
        } else if (inputForm.password.length < 8) {
            requestError = 'Password must be at least 8 characters';
        } else if (inputForm.confirmPassword === '') {
            requestError = 'Confirm password is required';
        } else if (inputForm.confirmPassword !== inputForm.password) {
            requestError = 'Confirm password must be the same as password';
        }

        if (requestError) {
            setInputForm({
                ...inputForm,
                requestError: requestError,
            });
            return;
        }

        try {
            const resp = await Register(inputForm.email, inputForm.password);
            localStorage.setItem('token', resp.data.token);
            setInputForm({
                ...inputForm,
                requestError: '',
            });
        } catch (error) {
            console.log(error);
            setInputForm({
                ...inputForm,
                requestError: error.response.data.Message,
            });
        }
    };

    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const checkInput = (e) => {
        const data = {
            ...inputForm,
            [e.target.name]: e.target.value
        }
        if (e.target.name === 'email') {
            if (e.target.value === '') {
                data.emailError = 'Email is required'
            } else if (!validateEmail(e.target.value)) {
                data.emailError = 'Email is invalid'
            } else {
                data.emailError = ''
            }
        }
        if (e.target.name === 'password') {
            if (e.target.value === '') {
                data.passwordError = 'Password is required'
            } else if (e.target.value.length < 8) {
                data.passwordError = 'Password must be at least 8 characters'
            } else {
                data.passwordError = ''
            }
        }
        if (e.target.name === 'confirmPassword') {
            if (e.target.value === '') {
                data.confirmPasswordError = 'Confirm Password is required'
            } else if (e.target.value !== inputForm.password) {
                data.confirmPasswordError = 'Confirm Password must be same as Password'
            } else {
                data.confirmPasswordError = ''
            }
        }

        return data
    }

    const onChange = (e) => {
        setInputForm({
            ...checkInput(e),
            [e.target.name]: e.target.value
        })
    }


    const switchRegister = () => {
        setInputForm({
            ...inputForm,
            isRegister: !inputForm.isRegister
        })
    }

    return (
        <Container maxWidth="sm" fixed className='mt-8 flex flex-col items-center'>
            <Box
                className='flex flex-col sm:py-8 items-center border-2 border-blue-400 rounded-xl shadow-lg item-center max-w-sm mx-auto'
            >
                <form className="flex flex-col gap-4 w-full px-4">
                    <h1 className='text-2xl font-bold'>{inputForm.isRegister ? 'Register' : 'Login'}</h1>
                    <hr />
                    <TextField id="email" label="Email" variant="outlined" name="email" size="small" error={!!inputForm.emailError} helperText={inputForm.emailError} value={inputForm.email} onChange={onChange} required />
                    <TextField id="password" label="Password" variant="outlined" name="password" size='small' error={!!inputForm.passwordError} helperText={inputForm.passwordError} value={inputForm.password} onChange={onChange} required type='password' />
                    {!inputForm.isRegister && <div className='text-sm text-blue-400 self-end select-none'>Forgot Password?</div>}
                    {inputForm.isRegister && <TextField id="confirmPassword" label="Confirm Password" variant="outlined" name="confirmPassword" size='small' error={!!inputForm.confirmPasswordError} helperText={inputForm.confirmPasswordError} value={inputForm.confirmPassword} onChange={onChange} required type='password' />}
                    <div className='w-full flex flex-col gap-1'>
                        <Button variant="contained" className='bg-blue-400 text-white' onClick={inputForm.isRegister ? onRegister : onLogin} >{inputForm.isRegister ? 'Register' : 'Login'}</Button>
                        {inputForm.requestError && <div className='text-red-400 text-sm self-center'>{inputForm.requestError}</div>}
                    </div>

                    <hr />
                    {
                        inputForm.isRegister ?
                            <div className='text-sm select-none'>Already have an account? <span onClick={switchRegister} className='text-blue-400'>Login</span></div>
                            :
                            <div className='text-sm select-none'>Don't have an account? <span onClick={switchRegister} className='text-blue-400'>Register</span></div>
                    }
                </form>
            </Box>
        </Container>

    );
}

export { LoginPage }