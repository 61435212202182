import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate } from "react-router-dom";


function Home(){
    const navigate = useNavigate();
    function onLogin(history){
        navigate('/login');
    }
    return(
        <div className='w-full px-0'>
            <div className='shadow-lg h-16 w-full flex justify-end p-3'>
                <Button variant="contained" className='bg-white' onClick={onLogin}>
                    Login
                </Button>
            </div>
            This page developed by Shengtong Jiang, Xuyou Cheng and Ziheng Zhang
        </div>
    )
}

export {Home}