import instance from "./base";

function GetUsers(page, perPage){
    return instance.get('/admin/user/list' , {
        params: {
            page,
            perPage
        }
    })
}

function SetUserTraffic(id, trafficAvailable, trafficTotal){
    return instance.post('/admin/user/transfer', {
        userID:id,
        transferAvailable:trafficAvailable,
        transferTotal:trafficTotal
    })
}

function GetUserTraffic(id,duration,type){
    return instance.post('/admin/user/traffic', {
        userID:id,
        duration:duration,
        type:type
    })
}

export {GetUsers, SetUserTraffic,GetUserTraffic}