
import axios from 'axios';

const token = localStorage.getItem('token')

const instance = axios.create({
    baseURL: 'http://localhost:8080/api/v1',
    timeout: 5000,
    crossdomain: true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
});

function setToken(token){
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default instance;
export {setToken}