import { AreaChart, Tooltip, CartesianGrid, XAxis, YAxis, Area, Legend, ResponsiveContainer } from 'recharts';
import { Tabs, Tab } from '@mui/material';
import React from 'react';
import { GetTraffic, GetUserInfo } from '../../../api/user';
import { TransferKiloBytesToGB, TransferKiloBytesToMB } from '../../../util/util';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ProgressBar } from '../../../componenet/componenet';
import { encode } from 'js-base64';
function DashboardHome() {
    const [chartConfig, setChartConfig] = React.useState({
        data: [],
        index: 0,
        duration: '15m',
    })
    const [userInfo, setUserInfo] = React.useState({
        email: '',
        transferAvailable: 0,
        transferTotal: 0,
    })
    const onTabChange = (e, i) => {
        if (i === chartConfig.index) return;
        setChartConfig({
            ...chartConfig,
            // index: i,
            loading: true,
        });
        refreshChart(i);
    }
    const mapUserTraffic = (dateOption) => {
        return (traffic) => {
            let d = new Date(traffic.timestamp * 1000);
            const formattedDate = d.toLocaleString('UTC', dateOption);
            return {
                'timestamp': formattedDate,
                'download': TransferKiloBytesToMB(traffic.download),
                'upload': TransferKiloBytesToMB(traffic.upload),
            }
        }
    }
    const refreshChart = async (index) => {
        // if (index === undefined) index = chartConfig.index;
        setChartConfig({
            ...chartConfig,
            index: index,
        });
        const conf = {
            ...chartConfig,
        }
        conf.index = index;
        let dateOption = {}
        if (index === 0) {
            dateOption = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }
            conf.duration = '15m';
        } else if (index === 1) {
            dateOption = {
                hour: 'numeric',
                minute: '2-digit',
                // second: 'numeric',
            }
            conf.duration = '1h';
        } else if (index === 2) {
            dateOption = {
                hour: 'numeric',
                minute: 'numeric',
            }
            conf.duration = '1d';
        }
        const traffic = await GetTraffic(conf.duration);
        let data = traffic.data.data.map(mapUserTraffic(dateOption));
        conf.data = data;

        const user = await GetUserInfo();
        setUserInfo(user.data.data)
        setChartConfig(conf);
    }
    React.useEffect(() => {
        refreshChart(0);
    }, [])
    const onClickClash = (e) => {
        e.preventDefault();
        const hostUrl = window.location.origin + '/api/v1/sub?type=clash&token=' + userInfo.subscriptionToken
        let url = 'clash://install-config?url=' + encodeURIComponent(hostUrl) + '&name=tiefblau'
        window.open(url,'_blank')
    }
    const onClickShadowrocket = (e) => {
        e.preventDefault();
        const hostUrl = window.location.origin + '/api/v1/sub?type=shadowrocket&token=' + userInfo.subscriptionToken
        let url = `shadowrocket://add/sub://${encode(hostUrl)}?remark=tiefblau`
        window.open(url,'_blank')
    }
    const maxDomain = (Math.max.apply(Math, chartConfig.data.map(function (o) { return Math.max(o.download, o.upload); })))
    // TransferKiloBytesToMB
    // TransferKiloBytesToGB
    return (
        <div className='flex flex-col justify-center w-full'>
            <div className='flex h-10 w-full justify-between'>
                <Tabs value={chartConfig.index} onChange={onTabChange} aria-label="basic tabs example" className="mb-8">
                    <Tab label="15 Min" />
                    <Tab label="1 Hour" />
                    <Tab label="1 Day" />
                </Tabs>
                <Button variant="outlined" onClick={(e) => { e.preventDefault(); refreshChart(chartConfig.index) }}>Refresh</Button>
            </div>
            <ResponsiveContainer width="100%" height={350}>
                <AreaChart data={chartConfig.data}
                    margin={{ top: 10, right: 30, left: 50, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="timestamp" />
                    {/* <YAxis /> */}
                    <YAxis label={{ value: 'network speed mb/s', angle: -90, position: 'left' }} domain={[0, maxDomain]} unit={'mb/s'} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                    <Area type="monotone" dataKey="download" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                    <Area type="monotone" dataKey="upload" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>
            <Divider sx={{ 'marginTop': '2rem' }} />
            <div className='my-4 border-gray-200 border-2'>
                <ProgressBar  value={(userInfo.transferAvailable - userInfo.transferTotal) / userInfo.transferAvailable} flipColor />
            </div>
            <div>Avaliable: {TransferKiloBytesToGB(userInfo.transferAvailable - userInfo.transferTotal)} / {TransferKiloBytesToGB(userInfo.transferAvailable)} GB</div>
            <div className='flex flex-col items-start'>
                <Button onClick={onClickClash}>Clash</Button>
                <Button onClick={onClickShadowrocket}>Shadowrocket</Button>
            </div>
        </div>
    )
}

export default DashboardHome;